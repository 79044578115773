export default [
  {
    title: 'Organization Management',
    route: 'organization-management',
    icon: 'MapPinIcon',
    action: 'read',
    resource: 'organization_management',
  },
  {
    title: 'Feedback Management',
    route: 'feedback-management',
    icon: 'TwitchIcon',
    action: 'read',
    resource: 'feedback_management',
  },
  {
    title: 'User Management',
    route: 'user_management',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'user_management',
  },
  {
    header: 'Account Management',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'user_management',
    children: [
      {
        title: 'Invoices',
        route: 'invoices_detail',
        action: 'read',
        icon: 'FileTextIcon',
        resource: 'customer_admin_management',
      },
      {
        title: 'Plan Management',
        route: 'plan_management',
        action: 'read',
        icon: 'RefreshCwIcon',
        resource: 'customer_admin_management',
      },
      {
        title: 'Payment Method',
        route: 'payment_method',
        icon: 'CreditCardIcon',
        action: 'read',
        resource: 'customer_admin_management',
      },
      {
        title: 'General Definitions',
        route: 'return_url_management',
        icon: 'GlobeIcon',
        action: 'read',
        resource: 'return_url_management',
      },
    ],

  },
  {
    header: 'Support System',
    icon: 'HelpCircleIcon',
    action: 'read',
    resource: 'support_management',
    children: [
      {
        title: 'Support Notifications',
        route: 'support',
        action: 'read',
        icon: 'MessageCircleIcon',
        resource: 'support_management',
      },
      // {
      //   title: 'FAQ',
      //   route: 'user_management',
      //   action: 'read',
      //   icon: 'TrendingUpIcon',
      //   resource: 'survey_management',
      // },
    ],

  },
]
