export default [
  {
    title: 'Müşteriler',
    route: 'customers-list',
    icon: 'UsersIcon',
    resource: 'admin',
  },
  {
    header: 'Plan Yönetimi',
    icon: 'RefreshCwIcon',
    resource: 'admin',
    children: [
      {
        title: 'Planlar',
        route: 'plan',
        icon: 'DatabaseIcon',
        resource: 'admin',
      },
      {
        title: 'Ülke Plan',
        route: 'plan_location',
        resource: 'admin',
        icon: 'FlagIcon',
      },
    ],
  },
  {
    title: 'Kupon İşlemleri',
    route: 'discount',
    icon: 'TagIcon',
    resource: 'admin',
  },
  {
    header: 'Genel Tanımlar',
    icon: 'SettingsIcon',
    resource: 'admin',
    children: [
      {
        title: 'Para Birimi',
        route: 'currency',
        resource: 'admin',
        icon: 'DollarSignIcon',
      },
    ],
  },
]
