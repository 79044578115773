<template>
  <b-button
    v-if="button_is_active"
    class="p-50"
    variant="danger"
    size="sm"
    @click="returnAdminScreen"
  >
    <feather-icon icon="UsersIcon" /> Admin Ekranı Dön
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import router from '@/router'
import axios from 'axios'

export default {
  components: {
    BButton,
  },
  data() {
    return {
    }
  },
  computed: {
    button_is_active() {
      if (localStorage.getItem('tempAccessToken', null) !== null) {
        return true
      }
      return false
    },
  },
  watch: {},
  methods: {
    async returnAdminScreen() {
      // TempAdmin token verileri set ediliyor
      localStorage.setItem('accessToken', localStorage.getItem('tempAccessToken'))
      localStorage.setItem('refreshToken', localStorage.getItem('tempRefreshToken'))
      // TempAdmin token verileri set ediliyor

      // Tempadmin token verileri siliniyor
      localStorage.removeItem('tempAccessToken')
      localStorage.removeItem('tempRefreshToken')
      // Tempadmin token verileri siliniyor
      router.push({ name: 'dashboard' })
      location.reload()
    },
  },
  setup() {},
}
</script>

<style>

</style>
